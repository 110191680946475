import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import dayjs from 'dayjs'
import "../public/cssGlobe/basic.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


// 配置
axios.defaults.baseURL = 'https://api.wangwdog.com'
// axios.defaults.baseURL = 'http://localhost:8090'
axios.interceptors.request.use(config => {
  // 为请求头对象，添加 Token 验证的 Authorization 字段
  config.headers.token = window.localStorage.getItem('token')
  // 在最后必须 return config
  return config
})

dayjs.locale('zh-cn')


const app =createApp(App)
app.config.globalProperties.$http = axios
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus).use(router).mount('#app')
