import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('@/components/Welcome.vue')
    },
    {
        path: '/login',
        component: () => import('@/components/user/login/Login.vue')
    },
    {
        path: '/Person',
        component: () => import('@/components/user/Person/personView.vue')

    },
    {
        path: '/dormHome',
        component: () => import('@/components/statistics/dormManage/dormHome.vue'),
        redirect: 'dormitoryManagement',
        children: [
            {
                name: 'dormUserManagement',
                component: () => import('@/components/statistics/dormManage/dormUserManagement.vue'),
                path: '/dormUserManagement',

            }, {
                name: 'dormitoryManagement',
                path: '/dormitoryManagement',
                component: () => import('@/components/statistics/dormManage/dormDown/dormitoryManagement.vue'),

            }, {
                name: 'dormScoreAdd',
                path: '/dormScoreAdd',
                component: () => import('@/components/statistics/dormManage/dormAdd/dormitoryAddManagement.vue'),
            }]
    },
    {
        path: '/cleanHome',
        component: () => import('@/components/statistics/cleanManage/cleanHome.vue'),
        redirect: 'cleanDown',
        children: [
            {
                name: 'cleanAddManagement',
                path: '/cleanAdd',
                component: () => import('@/components/statistics/cleanManage/cleanAdd/cleanAddManagement.vue')
            },
            {
                name: 'cleanDownManagement',
                path: '/cleanDown',
                component: () => import('@/components/statistics/cleanManage/cleanDown/cleanDownManagement.vue')
            },
        ]
    }, {
        path: '/sportHome',
        component: () => import('@/components/statistics/sportManage/sportHome.vue'),
        redirect: 'sportAdd',
        children: [
            {
                name: 'sportAddManagement',
                path: '/sportAdd',
                component: () => import("@/components/statistics/sportManage/sportAdd/sportAddManagement.vue")
            },
            {
                name: 'sportDownManagement',
                path: '/sportDown',
                component: () => import("@/components/statistics/sportManage/sportDown/sportManagement.vue")
            },
        ]
    }, {
        path: '/homeworkHome',
        component: () => import('@/components/statistics/homeworkManage/homeworkHome.vue'),
        redirect: 'homeworkDown',
        children: [
            {
                name: 'homeworkAddManagement',
                path: '/homeworkAdd',
                component: () => import('@/components/statistics/homeworkManage/homeworkAdd/homeworkAddManagement.vue')
            },
            {
                name: 'homeworkDownManagement',
                path: '/homeworkDown',
                component: () => import('@/components/statistics/homeworkManage/homeworkDown/homeworkManagement.vue')
            },
        ]
    }, {
        path: '/suspicionHome',
        component: () => import('@/components/statistics/suspicionManage/suspicionHome.vue'),
        redirect: 'suspicionDown',
        children: [
            {
                name: 'suspicionAddManagement',
                path: '/suspicionAdd',
                component: () => import('@/components/statistics/suspicionManage/suspicionAdd/suspicionAddManagement.vue')
            },
            {
                name: 'suspicionDownManagement',
                path: '/suspicionDown',
                component: () => import('@/components/statistics/suspicionManage/suspicionDown/suspicionManagement.vue')
            },
        ]
    }, {
        path: '/otherHome',
        component: () => import('@/components/statistics/otherManage/otherHome.vue'),
        redirect: 'otherDown',
        children: [
            {
                name: 'otherAddManagement',
                path: '/otherAdd',
                component: () => import('@/components/statistics/otherManage/otherAdd/otherAddManagement.vue')
            },
            {
                name: 'otherManagement',
                path: '/otherDown',
                component: () => import('@/components/statistics/otherManage/otherDown/otherManagement.vue')
            },
        ]
    }, {
        path: '/export',
        component: () => import('@/components/statistics/exportPage/exportManage.vue')
    },
    {
        path: "/specificAnalysis",
        component: () => import('@/components/statistics/analysisManagement/AnalysisHome.vue')

    }, {
        path: "/randomDraw",
        component: () => import('@/components/managementTool/randomDraw.vue')

    }, {
        path: "/poster/2024gaokao",
        component: () => import('@/components/Poster/24GaoKao/PosterWelcome.vue')

    }, {
        path: "/poster/2024gaokao/detail",
        component: () => import('@/components/Poster/24GaoKao/PosterMain.vue')

    }
]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})
// 设置特定的拦截器
router.beforeEach((to, from, next) => {
    if (to.path === '/login' || to.path === '/' || to.path === '/poster/2024gaokao/detail' || to.path === '/poster/2024gaokao') {
        return next(true)
    }
    // 获取token
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) {
        next('/login')
    } else {
        next(true)
    }
})
export default router
